<template>
    <div id="resourceIndex">
        <router-view
                :client="client"
        ></router-view>
    </div>
</template>

<script>
    import Api from "@/utils/api.js";
    import OSS from 'ali-oss';

    export default {
        data() {
            return {
                OSSdata:{},//oss数据
                client:null,//oss对象
            };
        },
        mounted() {
            var _this=this;
            this.getToken();
            // this.$_bus.$emit("getTerraToken");
            this.$_bus.$off("getTerraToken");
            this.$_bus.$on("getTerraToken", function() {
                _this.getToken();
            });

        },
        methods: {
            getToken(){
                this.$T_post(Api.obtain_token()).then(res => {
                    if (res.result.code === 0) {
                        console.log(res.data);
                        this.OSSdata=res.data;
                        sessionStorage.setItem("Terra_token", JSON.stringify(res.data));
                    }
                    this.client = new OSS({
                        region: res.data.region,
                        accessKeyId: res.data.accessKeyID,
                        accessKeySecret: res.data.secretAccessKey,
                        bucket: res.data.cloudBucketName,
                        stsToken:res.data.sessionToken
                    });
                }).catch(err => {
                    console.log(err);
                    this.$notice.error({
                        title: "发送失败!",
                        desc: err.toString()
                    });
                })
            },
        },
    };
</script>

<style scoped lang="scss">
    #resourceIndex{
        width: 100%;
        height: 100%;
    }
</style>
